<!--
 * @Author: your name
 * @Date: 2021-08-16 16:15:49
 * @LastEditTime: 2021-09-28 08:52:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\member\views\list.vue
-->
<template>
  <div>
    <page-header title="公司管理"></page-header>
    <div class="container">
      <el-form :model="search"
               class="dtb-search-form"
               inline
               label-width="100px">
        <el-form-item label="名称：">
          <el-input v-model="search.name"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="getList">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary"
                 class="add"
                 @click="onAddCompany">添加</el-button>
      <el-table :data="list"
                class="dtb-show-table">
        <el-table-column label="公司名称"
                         prop="company_name"></el-table-column>
        <el-table-column label="公司地址"
                         prop="company_address"></el-table-column>
        <el-table-column label="创建时间"
                         prop="created_at">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text"
                       @click="onEditItem(scope.row)">编辑</el-button>
            <el-button type="text"
                       @click="onDeleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="dtb-table-pagination">
        <span>共{{ totalCount }}条记录</span>
        <el-pagination background
                       layout="prev, pager, next"
                       :current-page.sync="search.page_no"
                       :page-size="search.page_size"
                       :total="totalCount"></el-pagination>
      </div>
    </div>
    <el-dialog :title="selItem && selItem.id ? '编辑公司' : '新增公司'"
               :visible.sync="isRecordDlg"
               width="400px">
      <el-form :model="selItem"
               inline
               label-width="100px">
        <el-form-item label="公司名称：">
          <el-input v-model="selItem.company_name"></el-input>
        </el-form-item>
        <el-form-item label="公司地址：">
          <el-input v-model="selItem.company_address"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-center">
        <el-button type="primary"
                   @click="submit">确定</el-button>
        <el-button @click="isRecordDlg = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from '../../common/components/PageHeader.vue'
import * as api from '../api/company'
export default {
  components: { PageHeader },
  data () {
    return {
      search: { name: '', page_no: 1, page_size: 10 },
      totalCount: 0,
      list: [],
      isRecordDlg: false,
      selItem: {},
    }
  },
  mounted () {
    this.getList()
  },
  watch: {
    'search.page_no' () {
      this.getList()
    }
  },
  methods: {
    submit () {
      const params = { ...this.selItem }
      api.edit(params).then(({ data: { code } }) => {
        if (code === 200) {
          this.isRecordDlg = false
          this.getList()
        }
      })
    },
    getList () {
      const params = { ... this.search }
      params.page_no = params.page_no - 1
      api.getList(params).then(({ data: { code, data } }) => {
        if (code === 200) {
          this.totalCount = data.total_count
          this.list = data.list
        }
      })
    },
    reset () {
      this.search = { name: '', page_no: 1, page_size: 10 }
    },
    onAddCompany () {
      this.selItem = { company_name: '', company_address: '', status: 1 }
      this.isRecordDlg = true
    },
    onEditItem (row) {
      this.isRecordDlg = true
      this.selItem = row
    },
    onDeleteItem (row) {
      this.$confirm('您确定要删除该公司吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.selItem = row
        this.selItem.status = 0
        this.submit()
      }).catch(() => {
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  flex: 1;
  background: white;
  margin: 18px;
  padding: 18px;
  .add {
    margin: 24px;
    margin-top: 0;
    display: block;
  }
}
</style>

<style lang="scss">
.el-dialog {
  &__body {
    border-top: 1px solid #eaeaea;
    background: white;
    padding: 20px;
  }
}
.record-header {
  background: black;
}
</style>